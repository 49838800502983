import {
  makeResponsive,
  findDirection,
  arrayKeyMapToObject,
  findRoundedDirection,
  findBorder,
  alignFlexSwitch,
} from '../../lib/style-functions';

const computeDivStyle = (props, context) => {
  const {
    d,
    pos,
    top,
    bottom,
    left,
    right,
    justify,
    align,
    flexDir,
    flexGrow,
    flexWrap,
    order,
    m,
    p,
    rounded,
    border,
    borderColor,
    h,
    maxH,
    minH,
    w,
    minW,
    maxW,
    cursor,
    bg,
    bgImg,
    bgSize,
    bgRepeat,
    bgPos,
    textSize,
    textColor,
    textWeight,
    textAlign,
    textTransform,
    fontFamily,
    shadow,
    transition,
    transform,
    transformOrigin,
    zIndex,
    overflow,
    opacity,
    hoverBg,
    hoverTextColor,
    hoverBorderColor,
    hoverShadow,
    focusBorderColor,
    isOpen,
    hover,
    offset,
    size,
    targetHover,
    theme,
    tag,
    // ========================================================
    // NEW PROPERTIES
    // ========================================================
    visibility,
    filter,
    gap,
    userSelect,
    aspect,
    gridGap,
    gridTempCols,
    placeContent,
    backfaceVisibility,
    maskImage,
    perspective,
    transformStyle,
    inset,
    pointerEvents,
    alignSelf,
    alignCenter,
    alignFlex,
    textShadow,
    ...rest
  } = props;

  const style = [
    { boxSizing: 'border-box' },
    findRoundedDirection(rounded, context.rounded),
    findDirection(m, 'margin'),
    findDirection(p, 'padding'),
    makeResponsive(d, 'display'),
    makeResponsive(pos, 'position'),
    makeResponsive(top, 'top'),
    makeResponsive(bottom, 'bottom'),
    makeResponsive(left, 'left'),
    makeResponsive(right, 'right'),
    makeResponsive(justify, 'justify-content'),
    makeResponsive(align, 'align-items'),
    makeResponsive(flexDir, 'flex-direction'),
    makeResponsive(flexGrow, 'flex'),
    makeResponsive(flexWrap, 'flex-wrap'),
    makeResponsive(order, 'order'),
    makeResponsive(h, 'height'),
    makeResponsive(maxH, 'max-height'),
    makeResponsive(minH, 'min-height'),
    makeResponsive(w, 'width'),
    makeResponsive(minW, 'min-width'),
    makeResponsive(maxW, 'max-width'),
    makeResponsive(cursor, 'cursor'),
    findBorder(border, borderColor, context.colors),
    makeResponsive(bg, 'background', context.colors),
    bgImg && { backgroundImage: `url(${bgImg})` },
    makeResponsive(bgSize, 'background-size'),
    makeResponsive(bgRepeat, 'background-repeat'),
    makeResponsive(bgPos, 'background-position'),
    makeResponsive(bgRepeat, 'background-repeat'),
    makeResponsive(textColor, 'color', context.colors),
    makeResponsive(textWeight, 'font-weight'),
    makeResponsive(textAlign, 'text-align'),
    makeResponsive(textTransform, 'text-transform'),
    makeResponsive(fontFamily, 'font-family', context.fontFamily),
    makeResponsive(transform, 'transform'),
    makeResponsive(transformOrigin, 'transform-origin'),
    makeResponsive(shadow, 'box-shadow', context.shadows),
    makeResponsive(transition, 'transition', context.transition),
    makeResponsive(textSize, 'font-size', context.textSize.size),
    makeResponsive(textSize, 'line-height', context.textSize.height),

    makeResponsive(overflow, 'overflow'),
    makeResponsive(opacity, 'opacity'),

    // ========================================================
    // NEW PROPERTIES
    // ========================================================
    makeResponsive(userSelect, 'user-select'),
    makeResponsive(visibility, 'visibility'),
    makeResponsive(filter, 'filter'),
    makeResponsive(gap, 'gap'),
    makeResponsive(aspect, 'aspect-ratio'),
    makeResponsive(gridGap, 'grid-gap'),
    makeResponsive(gridTempCols, 'grid-template-columns'),
    makeResponsive(placeContent, 'place-content'),
    makeResponsive(backfaceVisibility, 'backface-visibility'),
    makeResponsive(perspective, 'perspective'),
    makeResponsive(transformStyle, 'transform-style'),

    // SAFARI: To make sure border radius applies
    makeResponsive(maskImage, 'mask-image'),
    makeResponsive(inset, 'inset'),
    makeResponsive(pointerEvents, 'pointer-events'),
    makeResponsive(alignSelf, 'align-self'),
    makeResponsive(textShadow, 'text-shadow', context.textShadow),
    makeResponsive(zIndex, 'z-index', context.zIndex),

    // ========================================================
    // ALIGN FLEX SWITCH
    // ========================================================

    alignFlex && { display: 'flex', ...alignFlexSwitch(alignFlex) },

    {
      ':hover': {
        ...makeResponsive(hoverBg, 'background', context.colors),
        ...makeResponsive(hoverTextColor, 'color', context.colors),
        ...makeResponsive(hoverBorderColor, 'border-color', context.colors),
        ...makeResponsive(hoverShadow, 'box-shadow', context.shadows),
      },
    },
  ];

  return [rest, arrayKeyMapToObject(style)];
};

export default computeDivStyle;
