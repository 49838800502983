export const lightTheme = {
  mode: 'light',
  maxWidth: '1440px',
  mainPadding: '20px', // For scrollbar

  colors: {
    bgPrimary: `hsl(0, 0%, 100%)`, // WHITE
    bgSecondary: `hsl(228 89% 0%)`, // BLACK
    textPrimary: `hsl(0, 0%, 13%)`,
    textSecondary: `hsl(228 89% 100%)`,
    color1: `hsl(8 78% 49%)`,
    color2: `hsl(218 8% 39%)`,
    color2dark: `hsl(215, 16%, 23%)`,
    color2light: `hsl(223, 5%, 70%)`,
    color2lighter: `hsl(220, 4%, 85%)`,
    color2lightest: `hsl(220, 4%, 95%)`,
    color3: `hsl(200, 40%, 32%)`,
    white: '#FFFFFF',
    black: '#000000',
    transparent: 'rgba(0,0,0,0)',
  },

  shadows: {
    0: 'none',
    1: '0 0 1px 0 rgba(8, 11, 14, 0.06), 0 1px 1px 0 rgba(8, 11, 14, 0.1)',
    2: '0 0 1px 0 rgba(8, 11, 14, 0.06), 0 3px 3px -1px rgba(8, 11, 14, 0.1)',
    3: '0 0 1px 0 rgba(8, 11, 14, 0.06), 0 6px 6px -1px rgba(8, 11, 14, 0.1)',
    4: '0 0 1px 0 rgba(8, 11, 14, 0.06), 0 16px 16px -1px rgba(8, 11, 14, 0.1)',
    5: '0 0 1px 0 rgba(8, 11, 14, 0.06), 0 32px 40px -1px rgba(8, 11, 14, 0.1)',
    6: '0px 30px 60px -30px hsla(0, 0%, 0%, 0.3), 0px 50px 100px -20px hsla(240, 30%, 28%, 0.25), inset 0px -2px 6px hsla(210, 73%, 15%, 0.35)',
    7: 'hsla(0, 0%, 0%, 0.15) 1.95px 1.95px 2.6px',
    8: 'hsla(240, 30.069930069930066%, 28.03921568627451%, 0.25) 0px 50px 100px -20px, hsla(0, 0%, 0%, 0.3) 0px 30px 60px -30px',
    9: 'hsla(240, 30.069930069930066%, 28.03921568627451%, 0.25) 0px 13px 27px -5px, hsla(0, 0%, 0%, 0.3) 0px 8px 16px -8px',
    10: `rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset`,
    11: `rgba(50, 50, 93, 0.50) 0px 30px 80px -10px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -1px 6px 0px inset`,
  },

  textSize: {
    size: {
      tiny: '10px',
      caption: '12px',
      body: '14px',
      paragraph: '14px',
      subheader: '17px',
      title: '22px',
      heading: '26px',
      display1: '32px',
      display2: '40px',
      display3: '56px',
    },
  },

  fontSizes: {
    size00: '0.5rem',
    size0: '0.75rem',
    size1: '1rem',
    size2: '1.1rem',
    size3: '1.25rem',
    size4: '1.5rem',
    size5: '2rem',
    size6: '2.5rem',
    size7: '3rem',
    size8: '2rem',
  },

  rounded: {
    0: '0px',
    xs: '2px',
    sm: '3px',
    md: '5px',
    lg: '8px',
    xl: '16px',
    xxl: '32px',
    circle: '1000rem',
  },

  ease: {
    ease1: 'cubic-bezier(0, 0, 1, 1)',
    ease2: 'cubic-bezier(0.4, 0, 0.2, 1)',
    ease3: 'cubic-bezier(0.25, 0, 0.3, 1)',
    ease4: 'cubic-bezier(0.25, 0.1, 0.25, 1.0)',
    ease5: 'cubic-bezier(0.25, 0, 0.1, 1)',
  },

  zIndex: {
    z10: '10',
    z50: '50',
    z100: '100',
    z200: '200',
    z300: '300',
    z400: '400',
    z500: '500',
    z1000: '1000',
    zimportant: '2000',
    veryImportant: '2147483647',
  },

  textShadow: {
    shadow1: `rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px`,
    shadow6: `rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px`,
    shadow2: `rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px`,
    shadow3: `0px 3px 0px #b2a98f, 0px 14px 10px rgba(0, 0, 0, 0.15),
    0px 24px 2px rgba(0, 0, 0, 0.1), 0px 34px 30px rgba(0, 0, 0, 0.1)`,
    shadow4: `rgba(0, 0, 0, 0.16) 0px 1px 4px`,
    shadow5: `rgba(0, 0, 0, 0.24) 0px 3px 8px`,
    shadow7: `rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px`,
    shadow8: `rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px`,
    shadow9: `rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px`,
    shadow10: `rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px`,
    shadow11: `rgba(0, 0, 0, 0.04) 0px 3px 5px`,
    shadow12: `rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em`,
    shadow13: `rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px`,
    shadow14: `rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px`,
  },

  boxShad: {
    boxShadow0: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
    boxShadow1: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
    boxShadow2: `rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px`,
    boxShadow3: `rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px`,
    boxShadow4: `rgba(0, 0, 0, 0.16) 0px 1px 4px`,
    boxShadow5: `rgba(0, 0, 0, 0.24) 0px 3px 8px`,
    boxShadow6: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
    boxShadow7: `rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px`,
    boxShadow8: `rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px`,
    boxShadow9: `rgba(0, 0, 0, 0.1) 0px 4px 12px`,
    boxShadow10: `rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px`,
    boxShadow11: `rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px`,
    boxShadow12: `rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px`,
    boxShadow13: `rgba(17, 12, 46, 0.15) 0px 48px 100px 0px`,
    boxShadow14: `rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset`,
    boxShadow15: `rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px`,
    boxShadow16: `rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px`,
    boxShadow17: `rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px`,
    boxShadow18: `rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px`,
    boxShadow19: `rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`,
    boxShadow20: `rgb(38, 57, 77) 0px 20px 30px -10px`,
    boxShadow21: `rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset`,
    boxShadow22: `rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px`,
    boxShadow23: `rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px`,
    boxShadow24: `rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px`,
    boxShadow25: `rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;`,
    boxShadow26: `rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px`,
    boxShadow27: `rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px`,
    boxShadow28: `rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px`,
    boxShadow29: `rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px`,
    boxShadow30: `rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px`,
    boxShadow31: `rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px`,
    boxShadow32: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px`,
    boxShadow33: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px`,
    boxShadow34: `rgba(0, 0, 0, 0.05) 0px 0px 0px 1px`,
    boxShadow35: `rgba(0, 0, 0, 0.05) 0px 1px 2px 0px`,
    boxShadow36: `rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px`,
    boxShadow37: `rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px`,
    boxShadow38: `rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px`,
    boxShadow39: `rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px`,
    boxShadow40: `rgba(0, 0, 0, 0.25) 0px 25px 50px -12px`,
    boxShadow41: `rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;`,
    boxShadow42: `rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px`,
    boxShadow43: `rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px`,
    boxShadow44: `rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px`,
    boxShadow45: `rgba(0, 0, 0, 0.2) 0px 18px 50px -10px`,
    boxShadow46: `rgba(0, 0, 0, 0.1) 0px 10px 50px`,
    boxShadow47: `rgba(0, 0, 0, 0.04) 0px 3px 5px`,
    boxShadow48: `rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px`,
    boxShadow49: `rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em`,
    boxShadow50: `rgba(0, 0, 0, 0.1) 0px 1px 2px 0px`,
    boxShadow51: `rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;`,
    boxShadow52: `rgba(3, 102, 214, 0.3) 0px 0px 0px 3px`,
    boxShadow53: `rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px`,
    boxShadow54: `rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;`,
    boxShadow55: `rgba(0, 0, 0, 0.15) 0px 5px 15px 0px`,
    boxShadow56: `rgba(33, 35, 38, 0.1) 0px 10px 10px -10px`,
    boxShadow57: `blue 0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(31, 193, 27) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px`,
    boxShadow58: `rgb(85, 91, 255) 0px 0px 0px 3px, rgb(31, 193, 27) 0px 0px 0px 6px, rgb(255, 217, 19) 0px 0px 0px 9px, rgb(255, 156, 85) 0px 0px 0px 12px, rgb(255, 85, 85) 0px 0px 0px 15px`,
    boxShadow59: `rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;`,
    boxShadow60: `rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px`,
    boxShadow61: `rgba(17, 17, 26, 0.1) 0px 1px 0px`,
    boxShadow62: `rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px`,
    boxShadow63: `rgba(17, 17, 26, 0.1) 0px 0px 16px`,
    boxShadow64: `rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px`,
    boxShadow65: `rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px`,
    boxShadow66: `rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px`,
    boxShadow67: `rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px`,
    boxShadow68: `rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px`,
    boxShadow69: `rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px`,
    boxShadow70: `rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px`,
    boxShadow71: `rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px`,
    boxShadow72: `rgba(0, 0, 0, 0.15) 0px 3px 3px 0px`,
    boxShadow73: `rgba(0, 0, 0, 0.08) 0px 4px 12px`,
    boxShadow74: `rgba(0, 0, 0, 0.15) 0px 2px 8px`,
    boxShadow75: `rgba(0, 0, 0, 0.18) 0px 2px 4px`,
    boxShadow76: `rgba(0, 0, 0, 0.1) -4px 9px 25px -6px`,
    boxShadow77: `rgba(0, 0, 0, 0.2) 0px 60px 40px -7px`,
    boxShadow78: `rgba(0, 0, 0, 0.4) 0px 30px 90px`,
    boxShadow79: `rgba(0, 0, 0, 0.56) 0px 22px 70px 4px`,
    boxShadow80: `rgba(0, 0, 0, 0.2) 0px 20px 30px`,
    boxShadow81: `rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px`,
    boxShadow82: `rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;`,
    boxShadow83: `rgba(0, 0, 0, 0.09) 0px 3px 12px`,
    boxShadow84: `rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px`,
    boxShadow85: `rgba(0, 0, 0, 0.45) 0px 25px 20px -20px`,
    boxShadow86: `rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;`,
    boxShadow87: `rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;`,
    boxShadow88: `rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px`,
    boxShadow89: `rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px`,
  },
};
